<template>
  <section id="ticket poppins">
    <v-tabs class="tabs" color="primary" v-model="tab" background-color="transparent">
      <v-tab v-for="item in items" 
      :key="item" 
      active-class="active"
      class="item poppins secondary-2--text text-capitalize">
        {{item}}
      </v-tab>
    </v-tabs>
  <v-tabs-items v-model="tab" style="background-color: transparent">
    <v-tab-item v-for="item in items" :key="item">
        <TicketForm v-if="item === 'Submit a ticket'" class="px-3" submit="submit"/>
        <TicketList v-if="item === 'My Tickets'" :tickets="tickets" :tab="tab"/>
    </v-tab-item>
  </v-tabs-items>
  </section>
</template>


<script>
import header from '@/constants/tabs/tickets/ticket'
import { mapActions, mapMutations, mapState } from 'vuex'
import TicketForm from '@/components/user/ticket/TicketForm.vue'
import TicketList from '@/components/user/ticket/TicketList.vue';

export default {
 components: {
   TicketForm,
   TicketList
 },
  computed: {
    ...mapState('usr', {
      tickets: (state) => state.tickets,
    }),
  },
 
 methods: {
    ...mapActions('usr', ['getTicketsAction', 'submitTicketAction']),
    ...mapMutations(['alertMutation']),
 },
 data: () => ({
    tab: 0,
    items: header
 }),
}
</script>